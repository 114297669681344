<template>
  <FlagList :content="content"></FlagList>
</template>

<script>
  import FlagList from '@/views/components/flag/FlagList';
  import { FLAG_CONTENT } from '@/core/constants/enums';

  export default {
    name: 'FlagCastingPostList',
    components: {
      FlagList
    },
    data: () => ({
      content: FLAG_CONTENT.CASTING,
    })
  };
</script>

<style></style>
